import isObject from 'lodash.isplainobject'

export function transformNestedArrayKeys(array, fn) {
  return array.map((item) => {
    if (Array.isArray(item)) {
      return transformNestedArrayKeys(item, fn)
    } else if (isObject(item)) {
      // eslint-disable-next-line no-use-before-define
      return transformObjectKeys(item, fn)
    }

    return item
  })
}

export default function transformObjectKeys(object, fn) {
  if (Array.isArray(object)) {
    return transformNestedArrayKeys(object, fn)
  }

  return Object.keys(object).reduce((currentState, key) => {
    const value = object[key]
    const newState = { ...currentState }

    if (Array.isArray(value)) {
      newState[fn(key)] = transformNestedArrayKeys(value, fn)
    } else if (isObject(value)) {
      newState[fn(key)] = transformObjectKeys(value, fn)
    } else {
      newState[fn(key)] = value
    }

    return newState
  }, {})
}
